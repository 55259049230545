footer {
    background-color: #DFBDBD;
    padding: 16px 0;

    .container-md {
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }

    a { 
        margin-left: 8px;
     }
}