#header {
    width: 100%;
    height: 100vh;
    padding: 60px 0;
    font-size: 14px;
    position: relative;
    background: #3d566e;
    background: -moz-linear-gradient(left, #3d566e 0%, #2c3e50 100%);
    background: -webkit-linear-gradient(left, #3d566e 0%, #2c3e50 100%);
    background: linear-gradient(to right, #3d566e 0%, #2c3e50 100%);

    .inner-content {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;

        .header-img {
            width: 130px;
            margin: 0 auto;

            img {
                border-radius: 50%;
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    .header-content {
        max-width: 700px;
        width: 80%;
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
        color: #fff;

        p {
            font-size: 1.2em;
        }
    }
}