@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,700");

@import 'partials/variables';
@import 'partials/heading';
@import 'partials/spacing';
@import 'partials/typography';
@import 'partials/grid';
@import 'partials/homepage';
@import 'partials/over';
@import 'partials/contact';
@import 'partials/footer';

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        opacity: .8;
    }
}

ul {
    margin: 0;
    padding: 0 0 0 16px;
    color: #919191;
}

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    color: #464646;

    @media screen and (max-width: $bp-tablet-sm) {
        padding-bottom: 32px;
        margin-top: 64px;
    }
}

img {
    width: 100%;
    height: auto;
}

.clear {
    clear: both;
}

p {
    color: #919191;
}



nav {
    width: 100%;
    height: 96px;
    background: #fff;
    box-shadow: 0 0 17px 0 rgba(0,0,0,0.15);
    @media screen and (max-width:$bp-tablet-sm) {
        height: 64px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 13;
    }

    .hamburger {
        display: none;
        width: 32px;
        height: 24px;
        position: relative;
        z-index: 11;

        .line {
            $height: 4px;
            $color: #940000;
            display: block;
            height: $height;
            width: 100%;
            background-color: $color;
            border-radius: 4px;
            
            &:before,
            &:after {
                content: '';
                height: $height;
                width: 100%;
                display: block;
                background-color: $color;
                border-radius: 4px;
                position: absolute;
                top:50%;
                left: 0;
            }

            &:after {
                top: 100%;
            }
        }
    }

    @media screen and (max-width:$bp-tablet-sm) {
        .hamburger {
            display: block;
        }
        .items {
            display: none;
        }
    }

    .container-md {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        padding: 8px 0;
        width: 131px;
        position: relative;
        z-index: 11;
        @media screen and (max-width:$bp-tablet-sm) {
            width: 80px;
        }
    }

    .items {
        @media screen and (max-width:$bp-tablet-sm) {
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(white, 0.9);
            height: 100%;
            width: 100%;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        ul {
            list-style: none;

            li {
                color: $heading-color;
                display: inline-flex;
                @media screen and (max-width:$bp-tablet-sm) {
                    font-size: 18px;
                    display: block;
                    line-height: 32px;
                }

                a {
                    padding: 16px;
                    font-weight: 700;
                    @media screen and (max-width:$bp-tablet-sm) {
                        padding: 8px;
                    }
                }
            }
        }
    }
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60vh;
    min-height: 450px;

    @media screen and (max-width:$bp-tablet-sm) {
        padding: 48px 24px 64px;
        text-align: left;
        height: auto;
    }
    h4 {
        max-width: 886px;
        line-height: 40px;
        margin: 16px auto 0;
    }
}

section.pink {
    position: relative;
    background: #DFBDBD;
    padding: 128px 0;
    margin-bottom: 80px;
    z-index: 9;

    p {
        color: white;
    }

    @media screen and (max-width:$bp-tablet-sm) {
        padding: 48px 0;
    }

    &:before {
        content: '';
        position: absolute;
        top:0;
        width: 100%;
        height: 80px;
        left: 0; 
        background-image: url('../images/top-bow.svg');
        transform: translateY(-100%);
        background-repeat: no-repeat;
        background-position: top center;
        @media screen and (min-width: 1456px) {
            background-size: 200%;
        }
        @media screen and (max-width:$bp-tablet-sm) {
            left: -999%;
        }
    }
    &:after {
        content: '';
        position: absolute;
        bottom:0;
        width: 100%;
        height: 80px;
        left: 0;
        background-image: url('../images/bottom-bow.svg');
        transform: translateY(100%);
        background-repeat: no-repeat;
        background-position: top center;
        @media screen and (min-width: 1456px) {
            background-size: 200%;
        }
        @media screen and (max-width:$bp-tablet-sm) {
            left: -9999%;
        }
    }
}

.intro { 
    color: white;
    .portrait {
        margin-left: auto;
        line-height: 0;
        position: relative;
        max-width: 300px;

        @media screen and (max-width:$bp-tablet-sm) {
            margin: 32px auto 0;
        }
        
        img {
            border-radius: 4px;
            position: relative;
            z-index: 2;
        }
    }
}

.backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(148,0,0,0.50);
    box-shadow: 0 0 32px 0 rgba(61,61,61,0.14);
    z-index: 1;
    top: 0;
    left: 0;
    transform: translate(16px, 16px);
    border-radius: 4px;
}

.about {
    padding: 120px 0 168px 0;

    @media screen and (max-width:$bp-tablet-sm) {
        padding: 48px 0;
    }
}

.price {
    .card {
        background: #fff;
        box-shadow: 0 0 32px 0 rgba(178,178,178,0.50);
        border-radius: 4px;
        width: 100%;
        padding: 32px;
        
        p {
            color:#919191;;
        }
        
        ul {
            line-height: 32px;
        }
    }
}

.contact {
    padding: 16px 0 64px 0;

    h2 {
        text-align: center;
        margin-bottom: 32px;
    }
    .info { 
        list-style: none;
        line-height: 32px;
        max-width: 300px;
        margin-left: auto;
        @media screen and (max-width: $bp-tablet-sm) {
            margin-right: auto;;
        }
        li {
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            span {
                font-weight: 500;
            }
        }
    }
    .map {
        height: 300px;
        position: relative;
        iframe {
            position: relative;
            z-index: 2;
            overflow: hidden;
            border-radius: 4px;
            width: 100%;
            height: 100%;
        }
    }
}

.btn {
    display: inline-flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    background-color: #940000;
    font-size: 16px;
    color: #fff;
    text-align: center;
    transition: 200ms;
    font-weight: 700;

    &:hover {
        background-color: rgba(#940000, .85);
    }

    &.sm {
        padding: 8px 12px;
        font-size: 14px;
    }
}