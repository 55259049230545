div[class^='container'] {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

div[class*='col'] {
  min-height: 1px;

  &.no-pad {
    padding: 0 !important;
  }
}

.container {
  &-fluid {
    width: 100%;
  }

  &-lg {
    max-width: 1536px;

    &.wide {
      @media (min-width: 144px) {
        max-width: 1536px + 160px;
      }
    }

    .grid {
      .col-4.full {
        flex-basis: 33.33333333% !important;
        max-width: 100% !important;

        > div {
          max-width: 344px;
          margin: auto;
        }
      }

      .col-3.full {
        flex-basis: 25% !important;
        max-width: 100% !important;

        > div {
          max-width: 280px;
          margin: auto;
        }
      }
    }
  }

  &-md {
    max-width: 1280px;
  }

  &-lg,
  &-md {
    padding-left: 48px;
    padding-right: 48px;

    .grid {
      flex-direction: row;

      &.na {
        .col {
          &-12 {
            max-width: 824px;
          }
        }
      }

      &.na .col {
        &-6 {
          max-width: 536px;
        }

        &-4 {
          max-width: 344px;

          &.small {
            max-width: 288px;
          }
        }

        &-3 {
          max-width: 280px;
        }
      }
    }
  }

  &-sm {
    max-width: 1120px;
    padding-left: 48px;
    padding-right: 48px;

    .grid {
      &.na .col {
        &-6 {
          max-width: 480px;
        }

        &-4 {
          max-width: 304px;
        }

        &-3 {
          max-width: 224px;
        }

        &-12 {
          max-width: 768px;
        }
      }
    }
  }

  &-xsm {
    max-width: 776px;
    padding-right: 48px;
    padding-left: 48px;

    .grid.na {
      .col {
        &-6 {
          max-width: 308px;
        }
      }
    }
  }
}

.grid {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.around {
    justify-content: space-around;
  }

  &.between {
    justify-content: space-between;
  }

  &.text-center {
    text-align: center;
  }

  &.na {
    justify-content: space-between;

    &.center {
      justify-content: center !important;
    }

    &.around {
      justify-content: space-around;
    }
  }

  &.blocks {
    .col-6 .block {
      max-width: 1440px / 2 - 48;
      display: flex;
      justify-content: center;

      > div {
        max-width: 416px;
      }

      &.left {
        margin-left: auto;
        margin-right: 0;
      }
      &.right {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }

  .narrow {
    max-width: 352px !important;
  }

  .wide {
    max-width: 624px !important;
  }

  .col {
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 0;
  }

  .align-top {
    align-self: flex-start;
  }

  .align-bottom {
    align-self: flex-end;
  }

  .align-middle {
    align-self: center;
  }
  @for $i from 1 through 12 {
    .col-#{$i} {
      flex-basis: (100% / 12) * $i;
      max-width: (100% / 12) * $i;
      padding: 0 $bu;
    }
  }
}

.top {
  justify-content: flex-start !important;
  flex-direction: column;
  display: flex;
}

.bottom {
  justify-content: flex-end !important;
  flex-direction: column;
  display: flex;
}

.middle {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.fixed {
  flex: initial;
}

.right {
  margin-left: auto;
}
@media screen and (max-width: 1250px) {
  .container-lg,
  .container-md {
    .grid.na .col {
      &-6 {
        max-width: 45% !important;
      }

      &-3 {
        max-width: 22% !important;
      }

      &-4 {
        max-width: 30% !important;

        &.full {
          max-width: 33.33333% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .container-lg,
  .container-md,
  .container-sm {
    padding-left: 16px !important;
    padding-right: 16px !important;

    .grid.na {
      .col {
        &-4,
        &-6 {
          margin: $bu * 2 0;
          width: 100% !important;
          max-width: 100% !important;
          flex: 100% !important;
        }
      }
    }
    @for $i from 1 through 12 {
      .col-#{$i} {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}
@media screen and (min-width: 750px) and (max-width: $bp-desktop-sm) {
  // 1120
  .container {
    &-lg,
    &-md {
      padding-left: 24px !important;
      padding-right: 24px !important;

      .grid.na .col {
        &-4 {
          p {
            line-height: 24px;
          }
        }
      }
      @for $i from 1 through 12 {
        .col-#{$i} {
          flex-basis: 30%;
          max-width: 30%;
        }
      }
    }

    &-sm {
      .grid.na .col {
        &-6 {
          width: 47% !important;
          max-width: 47% !important;
        }

        &-4 {
          width: 30% !important;
          max-width: 30% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .container-sm {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
@media screen and (max-width: 700px) {
  .container-lg .grid.na .col-3,
  .container-md .grid.na .col-3 {
    margin: $bu * 2 0;
    width: 100% !important;
    max-width: 100% !important;
    flex: 100% !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: $bp-desktop-sm) {
  // 562 → 1024
  .container-lg,
  .container-md {
    padding: 0 24px;

    .grid {
      &.na {
        .col {
          &-3 {
            flex-basis: 46%;
            max-width: 46% !important;

            &:nth-child(3),
            &:nth-child(4) {
              margin-top: $bu * 3;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $bp-desktop-lg) {
  // 1440px
  .container-lg {
    .grid {
      &.na {
        .col {
          &-6 {
            max-width: 416px;
          }

          &-3 {
            max-width: 320px;
          }

          &-4 {
            max-width: 442px;
          }
        }
      }
    }
  }
}
