h1 {
  font-size: 48px;
  line-height: 64px;
  @media screen and (max-width: $bp-desktop-md) {
    font-size: 44px;
    line-height: 64px;
  }
  @media screen and (max-width: $bp-desktop-sm) {
    font-size: 44px;
    line-height: 64px;
  }
  @media screen and (max-width: $bp-tablet-md) {
    font-size: 40px;
    line-height: 60px;
  }
  @media screen and (max-width: $bp-mobile-lg) {
    font-size: 40px;
    line-height: 48px;
  }
  @media screen and (max-width: $bp-mobile-md) {
    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  font-size: 45px;
  line-height: 60px;
  @media screen and (max-width: $bp-desktop-md) {
    font-size: 40px;
  }
  @media screen and (max-width: $bp-tablet-md) {
    font-size: 34px;
    line-height: 48px;
  }
  @media screen and (max-width: $bp-mobile-lg) {
    font-size: 28px;
    line-height: 40px;
  }
  @media screen and (max-width: $bp-mobile-md) {
    font-size: 24px;
    line-height: 38px;
  }
}

h3 {
  font-size: 32px;
  line-height: 44px;
  @media screen and (max-width: $bp-desktop-sm) {
    font-size: 30px;
  }
  @media screen and (max-width: $bp-tablet-md) {
    font-size: 28px;
    line-height: 38px;
  }
  @media screen and (max-width: $bp-mobile-lg) {
    font-size: 24px;
    line-height: 36px;
  }
  @media screen and (max-width: $bp-mobile-md) {
    font-size: 20px;
    line-height: 30px;
  }
}

h4 {
  font-size: 23px;
  line-height: 32px;
  font-weight: 400;
  @media screen and (max-width: $bp-desktop-sm) {
    font-size: 22px;
    line-height: 30px;
  }
  @media screen and (max-width: $bp-tablet-md) {
    font-size: 20px;
  }
  @media screen and (max-width: $bp-mobile-lg) {
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: $bp-mobile-md) {
    font-size: 16px;
    line-height: 24px;
  }
}

h5 {
  font-size: 18px;
  line-height: 30px;
  @media screen and (max-width: $bp-tablet-md) {
    font-size: 17px;
    line-height: 28px;
  }
}

h6 {
  font-size: 16px;
}

p {
  font-size: 16px;
  line-height: 30px;
  @media screen and (max-width: $bp-mobile-lg) {
    line-height: 28px;
  }
}

.col-4 {
  font-size: 14px;
  line-height: 24px;
  @media screen and (max-width: $bp-mobile-lg) {
    font-size: 16px;
    line-height: 28px;
  }
}

.col-3 p {
  font-size: 14px;
  line-height: 26px;
  @media screen and (max-width: $bp-mobile-lg) {
    font-size: 16px;
    line-height: 28px;
  }
}
