.contact-page {
  .container-sec {
    padding-bottom: 32px;
  }
  h1 {
    margin-top: 64px;
  }
  form {
    margin-top: 48px;
    max-width: 364px;
    div {
      label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        color: #919191;
        margin-bottom: 8px;
      }
      input,
      textarea {
        display: block;
        width: 100%;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #C2C2C2;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 16px;
      }
      textarea {
        height: 144px;
        resize: none;
      }
    }
  }
  .confirmerino {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
  }
}