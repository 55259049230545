.over {
  .container-sec {
    margin-bottom: 64px;
    margin-top: 64px;
  }

  h1,h3 {
    margin-bottom: 12px;
  }

  .container-md,
  .container-sm {
    ul {
      li:not(nav) {
        margin-bottom: 12px;
      }
    }
  }
}