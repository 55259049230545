@each $size, $scale in $spacing-sizes {
  .padding-#{$size} {
    padding-top: round($bu * $scale);
    padding-bottom: round($bu * $scale);

    @media screen and (max-width: $bp-desktop-sm) {
      padding-top: round($bu * $scale - 8);
      padding-bottom: round($bu * $scale - 8);
    }

    @media screen and (max-width: $bp-tablet-md) {
      padding-top: round($bu * $scale * .75);
      padding-bottom: round($bu * $scale * .75);
    }

    @media screen and (max-width: $bp-mobile-lg) {
      padding-top: round($bu * $scale * .5 + 4);
      padding-bottom: round($bu * $scale * .5 + 4);
    }
  }

  .margin-#{$size} {
    margin-top: round($bu * $scale);
    margin-bottom: round($bu * $scale);

    @media screen and (max-width: $bp-desktop-sm) {
      margin-top: round($bu * $scale - 8);
      margin-bottom: round($bu * $scale - 8);
    }

    @media screen and (max-width: $bp-tablet-md) {
      margin-top: round($bu * $scale * .75);
      margin-bottom: round($bu * $scale * .75);
    }

    @media screen and (max-width: $bp-mobile-lg) {
      margin-top: round($bu * $scale * .5 + 4);
      margin-bottom: round($bu * $scale * .5 + 4);
    }
  }

  @each $side in $spacing-sides {
    .padding-#{$size}-#{$side} {
      padding-#{$side}: round($bu * $scale);

      @media screen and (max-width: $bp-desktop-sm) {
        padding-#{$side}: round($bu * $scale - 8);
      }

      @media screen and (max-width: $bp-tablet-md) {
        padding-#{$side}: round($bu * $scale * .75);
      }

      @media screen and (max-width: $bp-mobile-lg) {
        padding-#{$side}: round($bu * $scale * .5 + 4);
      }
    }

    .margin-#{$size}-#{$side} {
      margin-#{$side}: round($bu * $scale) !important;

      @media screen and (max-width: $bp-desktop-sm) {
        margin-#{$side}: round($bu * $scale - 8) !important;
      }

      @media screen and (max-width: $bp-tablet-md) {
        margin-#{$side}: round($bu * $scale * .75) !important;
      }

      @media screen and (max-width: $bp-mobile-lg) {
        margin-#{$side}: round($bu * $scale * .5 + 4) !important;
      }
    }
  }
}

.no-margin {
  margin: 0 !important;
}
