$bu: 8px;

// colors
$heading-color: #373737;
$background: #DFBDBD;

// fonts
$body-font-family: 'Roboto', 'Open Sans', sans-serif;;

// breakpoints
$bp-mobile-sm: 321px;
$bp-mobile-md: 376px;
$bp-mobile-lg: 414px;
$bp-tablet-sm: 562px;
$bp-tablet-md: 768px;
$bp-desktop-sm: 1120px;
$bp-desktop-md: 1280px;
$bp-desktop-lg: 1536px;

// Spacing units
$spacing-sizes: (
  'xxs': 1,
  'xs': 2,
  's': 3,
  'm': 5,
  'l': 8,
  'xl': 11,
  'xxl': 16,
  'xxxl': 25
);

$spacing-sides: (
  'top',
  'right',
  'bottom',
  'left'
);
